
  .scene {
    perspective: 1000px;
  }
  .card {
    width: 100%;
    height: 100%;
    position: relative;
    transform-style: preserve-3d;
    transition: transform 0.7s;
  }
  .card-face {
    position: absolute;
    width: 100%;
    height: 100%;
    backface-visibility: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    
    border: 2px solid #e2e8f0; /* Tailwind's gray-200 */
    border-radius: 10px;
  }
  .front {
    background: white;
  }
  .back {
    background: #f7fafc; /* Tailwind's gray-100 */
    transform: rotateY(180deg);
  }
  .scene:hover .card {
    transform: rotateY(180deg);
  }
  .text-bg {
    background-color: rgba(0, 0, 0, 0.5); /* Example text background */
    color: white;
    padding: 8px;
  }

